var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "languages-sidebar__title" }, [
        _vm._v("Languages"),
      ]),
      _vm.languages.status === _vm.OperationStatus.InProgress
        ? _vm._l(8, function (i) {
            return _c("v-skeleton-loader", {
              key: i,
              staticClass: "ma-2",
              attrs: { type: "list-item", elevation: "2", width: "100%" },
            })
          })
        : _vm.languages.status === _vm.OperationStatus.Failed
        ? _c("div", { staticClass: "languages-error" }, [
            _c("h4", { staticClass: "languages-error__text" }, [
              _vm._v(_vm._s(_vm.languages.error)),
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "languages-error__button",
                    attrs: { color: "primary", outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.fetchLanguages(_vm.waypointId)
                      },
                    },
                  },
                  [_vm._v("Retry")]
                ),
              ],
              1
            ),
          ])
        : _vm.languages.status === _vm.OperationStatus.Succeeded
        ? _vm._l(_vm.languages.value, function (language) {
            return _c(
              "div",
              {
                key: language.code,
                staticClass: "languages-sidebar__link-container",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "languages-sidebar__link",
                    attrs: {
                      to: {
                        name: "edit-translations",
                        params: { language: language.code },
                      },
                      "active-class": "languages-sidebar__link_active",
                    },
                  },
                  [_vm._v(" " + _vm._s(language.display) + " ")]
                ),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }