<template>
    <div>
        <div class="languages-sidebar__title">Languages</div>
        <template v-if="languages.status === OperationStatus.InProgress">
            <v-skeleton-loader
                v-for="i in 8"
                :key="i"
                type="list-item"
                elevation="2"
                width="100%"
                class="ma-2"
            />
        </template>
        <div
            v-else-if="languages.status === OperationStatus.Failed"
            class="languages-error"
        >
            <h4 class="languages-error__text">{{ languages.error }}</h4>
            <div class="d-flex justify-content-center">
                <v-btn
                    class="languages-error__button"
                    color="primary"
                    outlined
                    @click="fetchLanguages(waypointId)"
                >Retry</v-btn
                >
            </div>
        </div>
        <template v-else-if="languages.status === OperationStatus.Succeeded">
            <div
                v-for="language of languages.value"
                :key="language.code"
                class="languages-sidebar__link-container"
            >
                <router-link
                    :to="{
                  name: 'edit-translations',
                  params: { language: language.code },
                }"
                    class="languages-sidebar__link"
                    active-class="languages-sidebar__link_active"
                >
                    {{ language.display }}
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import {OperationStatus} from "helpers/common";
import {authHttp} from "@/http";

export default {
    name: "languages_sidebar",
    data: () => ({
        languages: {
            status: OperationStatus.Unknown,
            value: [],
            error: null,
        },
    }),
    mounted() {
        this.fetchLanguages(this.waypointId);
    },
    methods: {
        async fetchLanguages(waypointId) {
            this.languages.error = null;
            this.languages.value = [];

            if (!waypointId) {
                this.languages.status = OperationStatus.Unknown;
                return;
            }

            this.languages.status = OperationStatus.InProgress;
            authHttp
                .get(`/api/languages/waypoint/${this.waypointId}`)
                .then((res) => {
                    let languageNames = new Intl.DisplayNames(["en"], {
                        type: "language",
                    });
                    this.languages.value = res.data.languages
                        .filter((x) => x !== "en")
                        .map((el) => {
                            return {
                                code: el,
                                display: languageNames.of(el),
                            };
                        }); // en is a default language, should not be shown in a list
                    this.languages.status = OperationStatus.Succeeded;
                })
                .catch((e) => {
                    this.languages.status = OperationStatus.Failed;
                    const errorMessage =
                        "Unexpected error occurred during fetching of languages, please try again or contact tech support";
                    this.toast(errorMessage, "error");
                    this.languages.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
    },
    computed: {
        waypointId() {
            return this.$route.params.store;
        },
        OperationStatus: () => OperationStatus,
    },
    watch: {
        waypointId(newVal) {
            this.fetchLanguages(newVal);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.languages-sidebar {
    padding: 18px;

    &__title {
        color: $grey_mid_1;
        font-size: 18px;
    }

    &__link-container {
        margin-top: 10px;
        margin-left: 10px;
    }

    &__link {
        color: $brand_color;
        text-transform: uppercase;
        font-size: 16px;

        &_active {
            &:after {
                content: ">";
            }
        }
    }
}
.languages-error {
    margin-top: 10px;
    &__text {
        text-align: center;
    }
    &__button {
        color: $brand_color;
        margin-top: 15px;
    }
}
</style>
